import env from "app/utils/env";
import axios from "app/utils/http/http";
import {
	CHECK_STOPOVER_AVAILABILITIES,
	FETCH_ALL_STOPOVER_CITIES,
	FETCH_STOPOVER_ACCOMMODATION_DESCRIPTION,
	FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES,
	FETCH_STOPOVER_PACKAGE,
	FETCH_STOPOVER_QUOTE,
} from "app/pages/Stopover/stopoverActionTypes";
import { BOOK, BOOK_EXTERNAL_PAYMENT, FETCH_FLIGHT_OPTIONS, PRE_BOOK } from "app/actionTypes";
import { getCredentials } from "app/utils/auth";

// @see https://stopover.canary.perfectstay.io/swagger/index.html#/

export const fetchAllStopoverCities = ({ brand, locale }) => {
	const endpoint = env("STOPOVER_API_URL");

	return {
		type: FETCH_ALL_STOPOVER_CITIES,
		promise: axios.get(`${endpoint}/cities/${brand}/${locale}`),
	};
};

export const fetchStopoverAvailableDepartureDateAndCities = ({ uri, shop, productsVisibility }) => {
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES,
		promise: axios.get(`${endpoint}/${productsVisibility}/${shop}/${uri}.json`),
	};
};

export const fetchStopoverQuote = quotationPayload => {
	return {
		type: FETCH_STOPOVER_QUOTE,
		promise: axios.post(
			`${env("STOPOVER_API_URL")}/quote`,
			{
				...quotationPayload,
			},
			{
				headers: {
					"Content-type": "application/json",
				},
			}
		),
	};
};

export const fetchStopoverFlightOptions = payload => {
	return {
		type: FETCH_FLIGHT_OPTIONS,
		promise: axios.post(`${env("STOPOVER_API_URL")}/flightOptions`, payload, {
			headers: {
				"Content-type": "text/plain",
			},
		}),
	};
};

export const fetchStopoverAccommodationDescription = ({
	bookingFlowId,
	accommodationId,
	stopoverDuration,
}) => {
	return {
		type: FETCH_STOPOVER_ACCOMMODATION_DESCRIPTION,
		promise: axios.post(`${env("STOPOVER_API_URL")}/accommodationDescription`, {
			bookingFlowId,
			accommodationId,
			stopOverDuration: Number(stopoverDuration),
		}),
	};
};

export const checkStopoverAvailibilities = ({
	bookingFlowId,
	accommodationId,
	boardCode,
	price,
	abTests,
	stopoverDuration,
}) => {
	return {
		type: CHECK_STOPOVER_AVAILABILITIES,
		promise: axios.post(`${env("STOPOVER_API_URL")}/accommodationQuote`, {
			bookingFlowId,
			accommodationId,
			boardCode,
			price,
			abTests,
			stopOverDuration: Number(stopoverDuration),
		}),
	};
};

export const fetchStopoverPackage = ({ bookingFlowId, stopOverDuration }) => {
	return {
		type: FETCH_STOPOVER_PACKAGE,
		promise: axios.post(`${env("STOPOVER_API_URL")}/package`, {
			bookingFlowId,
			stopOverDuration,
		}),
	};
};

export const stopoverPreBook = data => {
	return {
		type: PRE_BOOK,
		promise: axios.post(`${env("STOPOVER_API_URL")}/preBook`, data, {
			headers: {
				"Content-type": "text/plain",
			},
		}),
	};
};

export const stopoverBookExternalPayment = ({ bookingFlowId }) => {
	const url = `${env("STOPOVER_API_URL")}/bookExternalPayment`;

	return {
		type: BOOK_EXTERNAL_PAYMENT,
		promise: axios.post(
			url,
			{ bookingFlowId },
			{
				headers: {
					"Content-type": "application/json",
				},
			}
		),
	};
};

export const stopoverBook = paymentPayload => {
	const url = `${env("STOPOVER_API_URL")}/book`;
	const { token } = getCredentials();
	const headers = {
		Authorization: token,
		"Content-type": "application/json",
	};
	return {
		type: BOOK,
		promise: axios.post(url, paymentPayload, { headers }),
	};
};
